import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { TableComponent } from '../../components/table'
import { useLazyGetAllVendorsQuery } from '../../redux-toolkits/vendors/vendors.slice'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { debounce } from 'lodash'
import { TextInput } from '../../components/inputs/textInput'
import Dropdown from '../../components/inputs/dropdown'
import { getVendorName, removeElementAtIndex } from '../../helpers'
import {
  useAddProductToSubVariantMutation,
  useCreateSubVariantMutation,
  useLazyGetSingleSubVariantQuery,
  useRemoveProductFromSubVariantMutation,
} from '../../redux-toolkits/products/products.slice'
import Spinner from '../../components/spinner/Spinner'
import { Button } from '../../components/button'
import { useFormik } from 'formik'
import Icon from '../../components/icon'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import { useModalControl } from '../../hooks/useModalControl'
import { SelectProductModal } from '../../components/modal/app_modals/selectProductModal'
import { PageHeader } from '../../components/typography/PageHeader'
import { Product } from '../../types/types'
import { AddSubVariantLabelModal } from '../../components/modal/app_modals/addSubVariantLabelModal'
import { CreateSubVariant } from '../../redux-toolkits/products/products.type'
import { ConfirmDeleteModal } from '../../components/modal/app_modals/confirmDeleteModal'
import { CreateSubVariantSchema } from '../../validation/validate'
import { DetailsCard } from '../../components/cards/DetailsCard'

const handleSearch = (searchFunction: (val?: any) => void, query?: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

type VariantProduct = {
  label: string
  name: string
  id: string
}

type Products = VariantProduct[]

export const CreateSubVariantPage = () => {
  const { vId, svId } = useParams<{ vId: string; svId: string }>()

  const [vendorSearch, setVendorSearch] = useState<string>()
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [variantProduct, setvariantProduct] = useState<VariantProduct>()

  const [getAllVendors, { data: vendors, isFetching: fetchingVendors }] =
    useLazyGetAllVendorsQuery()

  const [
    fetchSubVariantDetails,
    { data: subVariantDetails, isFetching: fetchingSubVariantDetails },
  ] = useLazyGetSingleSubVariantQuery()

  const [createSubVariant, { isLoading: creatingSubVariant }] =
    useCreateSubVariantMutation()

  const [addSubVariantProduct, { isLoading: addingProduct }] =
    useAddProductToSubVariantMutation()

  const [removerSubVariantProduct, { isLoading: removingProduct }] =
    useRemoveProductFromSubVariantMutation()

  const { modal, modalOpen, handleModalOpen, handleModalClose, setModalOpen } =
    useModalControl()

  const navigate = useNavigate()

  const initialValues: {
    value: string
    variant: string
    products: Products
    vendor: { _id: string; name: string }
  } = {
    value: '',
    variant: vId ?? '',
    products: [],
    vendor: {
      _id: '',
      name: '',
    },
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateSubVariantSchema,
    onSubmit: async (values) => {
      const payload: CreateSubVariant = {
        value: values.value,
        variant: values.variant,
        vendor: values.vendor._id,
        products: values.products.map((pro) => {
          return {
            label: pro.label,
            product: pro.id,
          }
        }),
      }

      const { data } = await createSubVariant(payload)

      if (data?.success) {
        navigate(`/products/variants/${vId}/subvariant/edit/${data.data._id}`)
      }
    },
  })

  useEffect(() => {
    handleDebouncedSearch(getAllVendors, {
      page: `1`,
      limit: NUMBER_OF_ITEMS_PER_PAGE.toString(),
      search: vendorSearch,
    })
  }, [vendorSearch])

  useEffect(() => {
    svId && fetchSubVariantDetails({ id: svId })
  }, [svId])

  useEffect(() => {
    if (subVariantDetails) {
      setFieldValue('value', subVariantDetails.data.variantOption.value)
      setFieldValue(
        'products',
        subVariantDetails.data.variantOption.products.map((prod) => {
          return {
            label: prod.label,
            name: prod.product.name,
            id: prod.product._id,
          }
        }),
      )
      setFieldValue('vendor', {
        _id: subVariantDetails.data.variantOption.vendor ?? '',
        name: subVariantDetails.data.variantOption.vendor ?? '',
      })
    }
  }, [subVariantDetails])

  return (
    <Layout>
      {svId && fetchingSubVariantDetails ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <PageHeader
            title={
              <>
                <button
                  type="button"
                  onClick={() => {
                    navigate(`/products/variants/${vId}`)
                  }}
                  className="soft-shrink"
                >
                  <Icon id={'left-caret'} width={16} height={16} />
                </button>
                {svId ? 'Back' : 'Create sub-variant'}
              </>
            }
            className="flex items-center gap-4"
          />

          {!svId && (
            <div className="flex w-full justify-end">
              <Button
                type="submit"
                primary
                label="Save"
                className="!w-full !max-w-[150px]"
                loading={creatingSubVariant}
                disabled={!isValid || creatingSubVariant}
                onClick={handleSubmit}
              />
            </div>
          )}

          {!svId && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-10">
              <TextInput
                type="text"
                name="value"
                placeholder="Name"
                value={values.value}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.value && touched.value ? errors.value : ''}
                hasIcon={false}
                className=""
                readOnly={svId !== undefined}
              />

              <Dropdown
                loading={fetchingVendors}
                className=""
                menuClassName="max-h-[200px]"
                widthClass="!w-full"
                disabled={svId !== undefined}
                menuButton={
                  <TextInput
                    type="text"
                    name="vendor"
                    placeholder="Vendor"
                    value={vendorSearch ? vendorSearch : values.vendor.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setVendorSearch(e.target.value)
                    }}
                    errors={
                      errors.vendor?._id && touched.vendor?._id
                        ? errors.vendor._id
                        : ''
                    }
                    hasIcon={false}
                    readOnly={svId !== undefined}
                    extra={
                      <Icon
                        id={'left-caret'}
                        width={16}
                        height={16}
                        className="-rotate-90 text-sec-black"
                      />
                    }
                    className="!border-divider-gray"
                  />
                }
                onClickMenuItem={(selection) => {
                  setFieldValue('vendor', {
                    _id: selection.value,
                    name: selection.name,
                  })
                  setVendorSearch(undefined)
                }}
                menuItems={vendors?.data.docs.map((vendor) => {
                  return {
                    name: getVendorName(vendor),
                    value: vendor._id,
                  }
                })}
              />
            </div>
          )}

          {svId && (
            <DetailsCard
              details={[
                {
                  title: 'Label',
                  value: values.value,
                },
                {
                  title: 'Vendor',
                  value: values.vendor.name,
                },
                {
                  title: 'Number of products',
                  value: subVariantDetails?.data.totalProducts ?? 0,
                },
              ]}
              className="my-10"
            />
          )}

          <TableComponent
            headers={['Label', 'Product id', 'Product name', ' ']}
            rows={
              values.products.length && values.products.length > 0
                ? values.products.map((product, idx) => {
                    return {
                      id: product.id,
                      content: [
                        <div key="">{product.label}</div>,
                        <div key="">{product.id}</div>,
                        <div key="">{product.name}</div>,
                        <Dropdown
                          key={`${product.id}-controls`}
                          menuButton={
                            <Icon
                              id="ellipses"
                              height={18}
                              width={18}
                              className=""
                            />
                          }
                          onClickMenuItem={() => {}}
                          menuItems={[
                            {
                              name: (
                                <button
                                  className="disabled:opacity-30 w-full text-left"
                                  onClick={(): any => {
                                    if (!svId) {
                                      const productArray: Products = JSON.parse(
                                        JSON.stringify(values.products),
                                      )

                                      setFieldValue(
                                        'products',
                                        removeElementAtIndex(productArray, idx),
                                      )
                                    } else {
                                      handleModalOpen('confirmRemovalModal')
                                      setvariantProduct(product)
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                              ),
                              value: '',
                            },
                          ]}
                        />,
                      ],
                    }
                  })
                : []
            }
            name="Products"
            showName
            loading={fetchingSubVariantDetails}
            isEmpty={[undefined, 0].includes(values.products.length)}
            currentPage={1}
            totalDataCount={subVariantDetails?.data.totalProducts}
          />

          <div className="flex justify-end items-center mt-10">
            <Button
              type="button"
              label={svId ? 'Add new product' : 'Add products'}
              primary
              size="medium"
              onClick={() => {
                handleModalOpen('selectProductModal')
              }}
              icon="plus"
              loading={addingProduct}
              loaderColor="white"
            />
          </div>
        </>
      )}

      {modal === 'selectProductModal' && modalOpen && (
        <SelectProductModal
          open={modal === 'selectProductModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {}}
          onSuccessCallback={(product) => {
            setSelectedProduct(product)
            handleModalClose()
            handleModalOpen('attachLabelModal')
          }}
        />
      )}

      <AddSubVariantLabelModal
        open={modal === 'attachLabelModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedProduct(undefined)
        }}
        onSuccessCallback={async (label) => {
          if (!svId && selectedProduct) {
            const productArray: Products = JSON.parse(
              JSON.stringify(values.products),
            )

            setFieldValue('products', [
              ...(productArray ?? []),
              {
                label: label,
                name: selectedProduct?.name,
                id: selectedProduct?._id,
              },
            ])

            handleModalClose()
            setSelectedProduct(undefined)
          } else if (svId && selectedProduct) {
            const { data } = await addSubVariantProduct({
              variantOptionId: svId,
              productId: selectedProduct._id,
              label: label,
            })

            handleModalClose()
            setSelectedProduct(undefined)

            if (data?.success) {
              fetchSubVariantDetails({ id: svId })
            }
          }
        }}
        product={selectedProduct}
      />

      <ConfirmDeleteModal
        open={modal === 'confirmRemovalModal' && modalOpen}
        setOpen={setModalOpen}
        message="This action cannot be reversed"
        title="Confirm removal"
        onCloseCallback={() => {
          setvariantProduct(undefined)
        }}
        onConfirm={async () => {
          if (svId && variantProduct) {
            const { data } = await removerSubVariantProduct({
              variantOptionId: svId,
              productId: variantProduct?.id,
            })

            if (data?.success) {
              fetchSubVariantDetails({ id: svId })
              handleModalClose()
            }
          }
        }}
        loading={removingProduct}
      />
    </Layout>
  )
}
