import React from 'react'
import { registerables, Chart } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { PRIMARY_COLOR } from '../../constants'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { prettyFormatNumber } from '../../helpers'
import zoomPlugin from 'chartjs-plugin-zoom'

interface Props {
  labels: string[]
  data: number[]
  width?: number
  height?: number
  yTitle?: string
  responsive: boolean
}

Chart.register(...registerables, ChartDataLabels, zoomPlugin)

const BarChart: React.FC<Props> = ({
  data,
  labels,
  width = 500,
  height = 200,
  yTitle,
  responsive,
}) => {
  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            label: 'Amount',
            backgroundColor: PRIMARY_COLOR,
            borderWidth: 0,
            barThickness: 12,
            borderRadius: 6,
            data: data,
          },
        ],
      }}
      options={{
        responsive,
        scales: {
          x: {
            grid: {
              display: true,
              lineWidth: 1,
              color: '#EAEAEA',
            },
            border: {
              display: false,
            },
            display: true,
            type: 'category',
          },
          y: {
            grid: {
              display: true,
              lineWidth: 1,
              color: '#EAEAEA',
            },
            display: true,
            labels: labels,
            beginAtZero: true,
            title: {
              display: yTitle !== undefined,
              text: yTitle,
            },
            max: Math.ceil(
              Math.max(...data) +
                data.reduce(
                  (accumulator, current) => accumulator + current,
                  0,
                ) /
                  data.length +
                1,
            ),
          },
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'y',
            },
            zoom: {
              mode: 'y',
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
            color: '#FF5020',
            font: {
              size: 10,
            },
            backgroundColor: '#ff50201a',
            borderRadius: 3,
            anchor: 'end',
            align: 'end',
            formatter: (val) => {
              return prettyFormatNumber(val)
            },
          },
        },
      }}
      width={width}
      height={height}
    />
  )
}

export default BarChart
