import React, { useState } from 'react'
import Layout from '../../layout'
import {
  useGetAllVendorsQuery,
  useInviteVendorMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import ReactPaginate from 'react-paginate'
import Spinner from '../../components/spinner/Spinner'
import { Plus } from 'lucide-react'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Vendor } from '../../types/types'
import ModalComponent from '../../components/modal'
import { TextInput } from '../../components/inputs/textInput'
import { TabNav } from '../../components/nav/tabNav'
import ActiveVendors from './ActiveVendors'
import PendingVendors from './PendingVendors'

const Vendors: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [tab, setTab] = useState<string>('active')
  const [page, setPage] = useState(1)

  return (
    <Layout>
      <PageHeader title="Creators" />

      <TabNav
        activeTab={tab}
        setActiveTab={(tab) => {
          setTab(tab)
        }}
        tabs={[
          {
            name: 'Active creators',
            id: 'active',
          },
          {
            name: 'Pending creators',
            id: 'pending',
          },
        ]}
        className="mb-10"
      />

      {tab === 'active' && <ActiveVendors />}
      {tab === 'pending' && <PendingVendors />}
    </Layout>
  )
}

export default Vendors
