export const COOKIE_KEY = process.env.REACT_APP_AUTH_COOKIE_KEY! ?? '@goo'
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
export const GOOD_ONLINE_BACKEND_BASE_URL =
  process.env.REACT_APP_GOOD_ONLINE_BACKEND_BASE_URL
export const IMAGES_BACKEND_BASE_URL =
  process.env.REACT_APP_IMAGES_BACKEND_BASE_URL
export const CURRENT_PAGE_NUMBER = 1
export const NUMBER_OF_ITEMS_PER_PAGE = 20
export const MAX_NO_PUBLISHED_LISTS = 10
export const PRIMARY_COLOR = '#F45D2C'

export const ORDERSTATUS = [
  {
    id: 1,
    name: 'PROCESSING',
    type: 'warn',
  },
  {
    id: 2,
    name: 'PENDING',
    type: 'warn',
  },
  {
    id: 3,
    name: 'READY_FOR_PICKUP',
    type: 'warn',
  },
  {
    id: 4,
    name: 'SCHEDULED',
    type: 'warn',
  },
  {
    id: 5,
    name: 'PREPARING_DELIVERY',
    type: 'warn',
  },
  {
    id: 6,
    name: 'SHIPPED',
    type: 'warn',
  },
  {
    id: 7,
    name: 'DELIVERED',
    type: 'success',
  },
  {
    id: 8,
    name: 'COMPLETED',
    type: 'success',
  },
  {
    id: 9,
    name: 'CANCELLED',
    type: 'fail',
  },
  {
    id: 10,
    name: 'ABANDONED',
    type: 'fail',
  },
]

export const SETTLEMENTSTATUS = [
  {
    id: 1,
    name: 'SUCCESS',
    type: 'success',
  },
  {
    id: 2,
    name: 'FAILED',
    type: 'fail',
  },
]

export const partyMappings = {
  GOOD_THING: 'Good Operations',
  OTHERS: 'Others',
}

export const DISABLE__DELIVERY_REQUEST__FROM = 5
