import React, { useState } from 'react'
import Layout from '../../layout'
import {
  useGetAllVendorsQuery,
  useInviteVendorMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import ReactPaginate from 'react-paginate'
import Spinner from '../../components/spinner/Spinner'
import { Plus } from 'lucide-react'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Vendor } from '../../types/types'
import ModalComponent from '../../components/modal'
import { TextInput } from '../../components/inputs/textInput'
import { getVendorName } from '../../helpers'
import { Checkbox } from '../../components/inputs/Checkbox'
import { NavLink, useNavigate } from 'react-router-dom'

const ActiveVendors: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const { data, isFetching } = useGetAllVendorsQuery({
    page: `${page}`,
    limit: '20',
  })

  const navigate = useNavigate()

  return (
    <>
      <div className="flex justify-end">
        <Button
          label="Invite creator"
          onClick={() => setOpenModal(true)}
          primary
          icon="plus"
          size="medium"
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total Creators"
          count={data?.data?.total ? data?.data?.total : 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Email', 'Phone', 'Address']}
        rows={
          data?.data.docs.map((vendor) => {
            return {
              id: vendor._id,
              content: [
                <NavLink
                  to={`/vendors/${vendor._id}`}
                  className={`hover:underline`}
                  key={`vendoer-${vendor._id}-name`}
                >
                  {getVendorName(vendor)}
                </NavLink>,
                vendor.businessInformation?.businessEmail ?? '',
                vendor.businessInformation?.businessPhonenumber ?? '',
                vendor?.businessInformation?.businessAddress?.address ?? '',
              ],
            }
          }) ?? []
        }
        name="vendors"
        loading={isFetching}
        isEmpty={!data?.data || data.data.docs.length < 1}
        totalDataCount={data?.data.total}
        currentPage={page}
        pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          setPage(page)
        }}
      />

      <InviteVendorModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  )
}

const InviteVendorModal: React.FC<{
  open: boolean
  closeModal: () => void
  vendor?: Vendor
}> = ({ open, closeModal, vendor }) => {
  const [inviteVendor, { isLoading: invitingVendor }] =
    useInviteVendorMutation()

  return (
    <ModalComponent
      title={'Invite Creator'}
      open={open}
      setOpen={() => {}}
      onCloseCallback={() => {
        closeModal()
      }}
    >
      <Formik
        initialValues={{
          name: vendor?.name ?? '',
          email: vendor?.email ?? '',
          splitPercentage: vendor?.splitPercentage ?? '',
          isSplitEnabled: vendor?.isSplitEnabled ?? false,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          isSplitEnabled: Yup.boolean().required(),
          splitPercentage: Yup.number()
            .min(15)
            .max(30)
            .when('isSplitEnabled', (isSplitEnabled, schema) => {
              return isSplitEnabled[0] ? schema.required() : schema.optional()
            }),
        })}
        onSubmit={async (values) => {
          await inviteVendor({
            ...values,
            splitPercentage: values.isSplitEnabled
              ? (values.splitPercentage as number)
              : undefined,
          }).then((resp) => {
            if (resp.data?.success) {
              closeModal()
            }
          })
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form className="flex flex-col gap-4">
            <TextInput
              name="name"
              placeholder="Creator Name"
              type="text"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.name && touched.name ? errors.name : ''}
              hasIcon={false}
            />
            <TextInput
              name="email"
              placeholder="Email"
              type="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.email && touched.email ? errors.email : ''}
              hasIcon={false}
            />

            <Checkbox
              checkStatus={values.isSplitEnabled}
              text="Enable split percentage"
              handleChange={(status) => {
                setFieldValue('isSplitEnabled', status)
              }}
            />

            {values.isSplitEnabled && (
              <TextInput
                name="splitPercentage"
                placeholder="Split percentage (15 - 30)"
                type="splitPercentage"
                value={String(values.splitPercentage)}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.splitPercentage && touched.splitPercentage
                    ? errors.splitPercentage
                    : ''
                }
                hasIcon={false}
              />
            )}
            <Button
              disabled={invitingVendor}
              loading={invitingVendor}
              type="submit"
              label="Proceed"
              primary
              className="!mt-6 !w-full"
            />
          </Form>
        )}
      </Formik>
    </ModalComponent>
  )
}

export default ActiveVendors
