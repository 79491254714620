import React, { useEffect, useState } from 'react'
import { TextInput } from './textInput'
import { debounce } from 'lodash'

const handleSearch = (searchFunction: (val: any) => void, query: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

export const SearchInput = ({
  searchFunction,
  placeholder = 'Search',
}: {
  searchFunction: (val: string) => void
  placeholder?: string
}) => {
  const [searchParams, setSearchParams] = useState<string>()

  useEffect(() => {
    handleDebouncedSearch(searchFunction, searchParams)
  }, [searchParams])

  return (
    <TextInput
      placeholder={placeholder}
      hasIcon
      iconSvg="search"
      iconDimension={18}
      className="
            !bg-gray-bg !rounded-[32px] 
            !border-transparent !text-sec-black !py-3
          "
      value={searchParams ?? ''}
      onChange={(e) => setSearchParams(e.target.value)}
    />
  )
}
