import React, { useState } from 'react'
import Layout from '../../layout'
import OrderTable from '../orders/OrderTable'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { SearchInput } from '../../components/inputs/searchInput'
import { useParams } from 'react-router-dom'
import { QueryProps } from '../../types/types'
import { Status } from '../../components/cards/statusTag'
import Icon from '../../components/icon'
import { useGetSingleCustomersQuery } from '../../redux-toolkits/user/user.slice'
import Spinner from '../../components/spinner/Spinner'
import { format } from 'date-fns'
import { useGetSingleVendorQuery } from '../../redux-toolkits/vendors/vendors.slice'

export const SingleVendorPage: React.FC = () => {
  const { id } = useParams()

  const [query, setQuery] = useState<QueryProps>({
    skip: 0,
    limit: 20,
    search: undefined,
  })

  const { data: vendor, isFetching } = useGetSingleVendorQuery({
    id: id ?? '',
  })

  return (
    <Layout>
      {isFetching ? (
        <Spinner fullScreen={false} />
      ) : (
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2 text-sec-black">
            <div
              className="
                w-[80px] h-[80px]
                rounded-full bg-[#4F122A]
                flex items-center justify-center
                text-lg text-white font-medium
            "
            >
              {vendor?.data?.Profile?.logo ? (
                <img
                  src={vendor?.data?.Profile?.logo}
                  className="h-full w-full object-cover"
                />
              ) : (
                vendor?.data?.businessDetails?.businessName.charAt(0)
              )}
            </div>
            <p className="text-[40px] text-black font-recoleta-bold">
              {vendor?.data?.businessDetails?.businessName}
            </p>
            <p>
              Joined{' '}
              {vendor?.data?.businessDetails?.createdAt
                ? format(
                    vendor?.data?.businessDetails?.createdAt,
                    'MMM dd, yyyy',
                  )
                : ''}
            </p>
            <p>
              Owned by {vendor?.data?.Profile?.firstName}{' '}
              {vendor?.data?.Profile?.lastName}
            </p>
            <div className="flex items-center gap-2">
              <Icon id="envelope" width={24} height={24} />
              <p>{vendor?.data?.businessDetails?.businessEmail}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon id="phone-icon" width={24} height={24} />
              <p>{vendor?.data?.businessDetails?.businessPhonenumber}</p>
            </div>
            <div className="flex items-center gap-2">
              <p>Creator&apos;s business status:</p>
              <Status
                text={vendor?.data?.Profile?.deleted ? 'Inactive' : 'Active'}
                type={vendor?.data?.Profile?.deleted ? 'fail' : 'success'}
              />
            </div>
          </div>

          <CountCardContainer>
            <CountCard
              count={0}
              text="Total Revenue"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={0}
              text="Total Order Count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={0}
              text="Total Number of Customers"
              icon=""
              isCurrency={false}
            />
          </CountCardContainer>

          <div className="flex flex-col gap-6">
            <div className="flex flex-wrap items-center justify-between gap-4 mb-10">
              <div className="!w-full md:!max-w-[400px]">
                <SearchInput
                  searchFunction={(search) => {
                    setQuery({
                      ...query,
                      search: search === '' ? undefined : search,
                      skip: 0,
                    })
                  }}
                  placeholder="Search"
                />
              </div>

              <Dropdown
                menuClassName=""
                menuButton={
                  <Button
                    label={`Filter: `}
                    primary={false}
                    icon="filter"
                    className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
                  />
                }
                onClickMenuItem={(selected) => {}}
                menuItems={[
                  { name: 'All', value: 'all' },
                  { name: 'Vendor', value: 'VENDOR' },
                  { name: 'Good Operations', value: 'GOOD' },
                  { name: 'Others', value: 'OTHERS' },
                ]}
              />
            </div>

            <OrderTable
              orders={[]}
              currentPage={1}
              totalDataCount={0}
              pageLimit={Number(query.limit)}
              onPageChange={() => {}}
              loading={false}
              showCustomerName={false}
              name="Settlement History"
            />
          </div>
        </div>
      )}
    </Layout>
  )
}
